import { useState } from "react";

import { FaCode } from "react-icons/fa";
import { SiTailwindcss } from "react-icons/si";
import { SiMaterialdesignicons } from "react-icons/si";

export default function Resume() {
  const [darkMode, setDarkMode] = useState(false);

  return (
    <div
      className={
        darkMode
          ? "dark m-0 font-rubik font-[300] px-4"
          : "bg-[#FBFBFB] font-rubik font-[300] px-4"
      }
    >
      <div className="lg:px-[20vw] dark:bg-gray-900 ">
        <title>Berk Çınar</title>
        <div className="">
          <section className=" flex items-center justify-center mb-8">
            <div className="max-w-4xl mx-auto flex items-center grid grid-cols-1 md:flex">
              <div className="flex mx-auto">
                <div className="bg-gradient-to-b  from-teal-500 max-w-[15rem] max-h-[15rem] h-40 w-40 lg:w-80 lg:h-80 relative overflow-hidden rounded-full ">
                  <img
                    src="/berk-profile.jpg"
                    className="object-cover w-full h-full"
                    alt="Berk Profile"
                  />
                </div>
              </div>
              <div className="text-left md:p-10 mt-10">
                <h2 className="text-4xl pt-4 text-teal-600 text-center font-medium dark:text-teal-400">
                  Hi, I'm Berk.
                </h2>
                <h3 className="text-xl lg:text-2xl py-2 dark:text-white">
                  Mobile & Frontend Developer with a focus on building robust
                  and responsive applications using modern technologies.
                </h3>
                <div className="text-md pt-2 leading-8 text-gray-800 dark:text-white max-w-xl md:text-xl"></div>
                {/*     <div className="text-5xl flex justify-center gap-16 py-6 text-gray-600 dark:text-gray-400">
        <a href="https://github.com/berk-cinar" className="hover:text-teal-600">
          <AiFillGithub />
        </a>
        <a href="https://www.linkedin.com/in/berkcinr/" className="hover:text-teal-600">
          <AiFillLinkedin />
        </a>
      </div> */}
              </div>
            </div>
            <div></div>
          </section>
          <span className="text-lg lg:text-xl font-rubik font-[300] mt-16">
            I have 2 years of industry experience working on diverse projects in
            areas such as E-commerce, Health Tech, FinTech, Social Media, and
            Educational Platforms. My expertise lies in building responsive,
            intuitive, and visually appealing mobile and web applications using
            modern frameworks and technologies like React, React Native, and
            Flutter.
          </span>
          {/* EXPERIENCE */}
          <section>
            <div className="rounded-lg mx-auto my-16">
              <h2 className=" text-2xl lg:text-3xl font-semibold text-teal-600 mb-8">
                Professional Experience
              </h2>

              <div className="mb-16 text-xl">
                <span className="text-xl lg:text-2xl font-semibold text-gray-700">
                  Mobile Developer •{" "}
                  <span className="text-lg lg:text-2xl font-normal text-gray-700">
                    Satisgaranti.com
                  </span>
                </span>

                <p className="text-gray-600 mb-4">Aug 2023 - June 2024</p>
                <ul className="list-small-disc text-lg lg:text-xl list-inside ml-4 text-gray-700 mt-2">
                  <li>
                    <span>Feature Implementation:</span> Developed key
                    e-commerce functions like product listing, search,
                    filtering, and payment processes (such as Iyzico, Lidio,
                    etc.)
                  </li>
                  <li>
                    <span>Backend Data Integration:</span> Integrated backend
                    data via RESTful APIs for dynamic user experiences in mobile
                    apps
                  </li>
                  <li>
                    <span>Dynamic Component Creation:</span> Crafted dynamic
                    components with widgets, elevating app functionality and
                    user engagement.
                  </li>
                </ul>
              </div>

              <div className="mb-16 text-lg lg:text-xl ">
                <span className="text-xl lg:text-2xl font-semibold text-gray-700">
                  Frontend UI Developer •{" "}
                  <span className="text-lg lg:text-2xl font-normal text-gray-700">
                    Engcode (UK)
                  </span>
                </span>
                <p className="text-gray-600  mb-4">Jan 2023 - Aug 2023</p>
                <ul className="list-inside ml-4 text-gray-700 mt-2">
                  <li>
                    <span>Responsive Web Design:</span> Created responsive web
                    designs using React, enhancing user experience and
                    accessibility
                  </li>
                  <li>
                    <span>Utilisation of UI Libraries:</span> Mastered React
                    Kendo, Tailwind, and MUI Libraries to develop advanced UI
                    components, boosting site functionality.
                  </li>
                </ul>
              </div>

              <div className="mb-6 text-lg lg:text-xl ">
                <span className="text-xl lg:text-2xl font-semibold text-gray-700">
                  Frontend Developer Intern •{" "}
                  <span className="text-lg lg:text-2xl font-normal text-gray-700">
                    Mindtro
                  </span>
                </span>

                <p className="text-gray-600  mb-4">July 2022 - Nov 2022</p>
                <ul className="list-inside ml-4 text-gray-700 mt-2">
                  <li>
                    <span>Swagger for Backend Communication:</span> Leveraged
                    Swagger tools for effective data display from API
                    interactions, enhancing data integration and UX
                  </li>
                  <li>
                    <span>
                      Managing Application State with Redux Ecosystem:
                    </span>{" "}
                    Implemented complex state management using Redux,
                    Redux-Thunk, and Redux-Saga, improving app performance and
                    maintainability.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div className="container mx-auto my-16">
              <div className="grid grid-cols-1 md:grid-cols-2  gap-2">
                <div className="mb-16 text-xl pr-8">
                  <h2 className="text-2xl lg:text-3xl font-semibold text-teal-600 mb-12 ">
                    Education & Trainings
                  </h2>
                  <div className="mb-6">
                    <p className="text-lg lg:text-2xl font-semibold text-gray-700">
                      Kırıkkale University
                    </p>
                    <span className="text-lg lg:text-2xl font-normal text-gray-700">
                      Bachelor Degree
                    </span>
                    <p className="text-gray-600 ">July 2016 - January 2022</p>
                    <p className="text-xl text-gray-500">
                      Mechanical Engineering
                    </p>
                  </div>
                  <div className="mb-6 ">
                    <p className="text-lg lg:text-2xl font-semibold text-gray-700">
                      Istanbul Chamer of Commerce, ICOC (ITO)
                    </p>
                    <span className="text-lg lg:text-2xl font-normal text-gray-700">
                      Frontend Developer Program
                    </span>
                    <p className="text-gray-600 ">Mar - May 2023</p>
                    <p className="text-xl text-gray-500">
                      Frontend Developer Program
                    </p>
                  </div>
                  <div className="mb-6">
                    <p className="text-lg lg:text-2xl font-semibold text-gray-700">
                      Kodluyoruz Akademi
                    </p>
                    <span className="text-lg lg:text-2xl font-normal text-gray-700">
                      JavaScript & React Bootcamp
                    </span>
                    <p className="text-gray-600 ">Aug - Sep 2023</p>
                    <p className="text-xl text-gray-500">
                      JavaScript & React Bootcamp
                    </p>
                  </div>
                  <div>
                    <p className="text-lg lg:text-2xl font-semibold text-gray-700">
                      BTK Akademi
                    </p>
                    <span className="text-lg lg:text-2xl font-normal text-gray-700">
                      Usability of Websites
                    </span>
                    <p className="text-gray-600 ">Nov 2023</p>
                    <p className="text-xl text-gray-500">UX Design</p>
                  </div>
                </div>
                <div className="mb-16 text-lg lg:text-xl">
                  <h2 className="text-2xl lg:text-3xl font-semibold text-teal-600 mb-12">
                    Qualifications
                  </h2>
                  <div className="mb-8">
                    <span className="text-lg lg:text-2xl font-semibold text-gray-700 mb-2">
                      Language
                    </span>
                    <p>Turkish (Native), English (C1), German (A2)</p>
                  </div>
                  <div className="mb-8">
                    <span className="text-lg lg:text-2xl font-semibold text-gray-700">
                      Skills
                    </span>
                    <p>
                      Kendo React, Tailwind, Bootstrap, Semantic UI, XCode,
                      Android, Restful API, Git, HTTP Requests, Semantic UI,
                      Material UI, Tailwind, HTML, CSS, SCSS, SASS, JavaScript,
                      TypeScript, React Native, React, React Native, Redux,
                      Redux Thunk, Saga, Figma, Adobe XD, MS Office, Jira,
                      Agile, Scrum, Kanban
                    </p>
                  </div>
                  <div className="mb-4">
                    <span className="text-lg lg:text-2xl font-semibold text-gray-700 mb-2">
                      Tools
                    </span>
                    <p>
                      Graphic design: Adobe Photoshop, Illustrator, Indesign
                    </p>
                    <p>Web design: Adobe Dreamweaver</p>
                    <p>
                      Prototyping: Sketch, Figma, Adobe XD, Framer, Invision{" "}
                    </p>
                    <p>UI Animations: Principle</p>
                    <p>User research: Dovetail</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
