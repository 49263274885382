import Bottom from "./components/Bottom";
import Resume from "./pages/Resume";
import Navbar from "./components/Navbar";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Casestudies from "./pages/Casestudies";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <Router>
      <div>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Resume />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/casestudies" element={<Casestudies />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Bottom />
      </div>
    </Router>
  );
}

export default App;
