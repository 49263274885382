import React, { useEffect, useState } from "react";
import axios from "axios";
import { MdOutlineLaptopChromebook } from "react-icons/md";

import { FaGithub } from "react-icons/fa";

const CaseStudies = () => {
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    axios
      .get("/casestudies.json")
      .then((response) => setMovies(response.data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="md:px-[20vw] bg-[#FBFBFB]">
      <section className=" lg:py-10">
        <div className="dark:text-white gap-10 pt-6">
          {movies.map((item) => (
            <div className="md:flex mb-20">
              <div className="  md:w-[30%] bg-white p-8 rounded-tl-sm rounded-bl-sm">
                <div className="pt-6">
                  <h3 className="text-lg font-semibold lg:mb-1 dark:text-black">
                    {item.brand} <br />
                  </h3>
                  <h3 className="text-sm mb-6 dark:text-black">
                    {item.title} <br />
                  </h3>
                </div>

                <h3 className="text-sm dark:text-black">{item.description}</h3>
                <h4>
                  <div className="lg:min-h-[140px] dark:text-black">
                    <span className=""></span>
                    <br />
                    <span>{item.technologies}</span>
                    <span>
                      {item.id == 3 ? (
                        <>
                          <br />
                          <span>
                            <span className="font-bold dark:text-black">
                              Server Side:{" "}
                            </span>
                            {item.serverside}
                          </span>
                          <br />
                          <span className="font-bold dark:text-black">
                            Database:{" "}
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      {item.database}
                    </span>
                    <br />
                    <span className=" font-bold">UI:</span>
                    {item.ui}
                  </div>
                </h4>
                <div className=" justify-start lg:mb-12 flex text-white dark:text-white">
                  {/*       <a href={item.repo} target="_blank" rel="noreferrer">
                    <button className="py-2 px-6 mr-4  rounded-md bg-black">
                      <FaGithub className="h-6 w-6" />
                    </button>
                  </a> */}
                  <a href={item.demo} target="_blank" rel="noreferrer">
                    <button className="py-2 px-6 mt-6 rounded-md border-black border-[1px]">
                      <MdOutlineLaptopChromebook className="h-6 w-6 text-black" />
                    </button>
                  </a>
                </div>
              </div>
              <div
                className="relative md:w-[70%] pt-4 dark:bg-[#072a3e] rounded-tr-sm rounded-br-sm"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, #F1F3F5, #F1F3F5 50%, #DDE3E9 50%)",
                }}
              >
                {/*  {item.id === 1 ? (
               <>
               <div className="grid grid-cols-4 justify-center mx-32 pt-0 gap-8">
                 {item.image.map((a, index) => (
                   <div key={index} className="relative ">
                     <img
                       src="phone.png"
                       className="h-full"
                       alt="images"
                     />
                     <img
                       src={a}
                       className="absolute top-1/2 left-1/2 rounded-md transform -translate-x-1/2 -translate-y-1/2 h-[89%]"
                       alt="images"
                     />
                   </div>
                 ))}
               </div>
             </>
                ) : (
                  <img
                    src={item.image}
                    className="rounded-tr-xl rounded-br-xl object-cover w-full"
                    layout="responsive"
                    alt="images"
                  />
                )}  */}
                <img
                  src={item.image}
                  className="rounded-tr-xl rounded-br-xl object-cover w-full"
                  layout="responsive"
                  alt="images"
                />
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default CaseStudies;
