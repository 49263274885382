import React, { useState } from "react";
import { AiFillPhone } from "react-icons/ai";
import { HiLocationMarker } from "react-icons/hi";
import { AiFillMessage } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

const Bottom = () => {
  const [darkMode, setDarkMode] = useState(false);

  console.log(darkMode);

  return (
    <div className={darkMode ? "dark font-rubik " : "font-rubik"}>
      <div class="pt-4 text-center  bg-gradient-to-r from-gray-700 to-gray-900 mx-auto">
        <h3 className="text-white px-6 text-lg  md:text-2xl pt-[5vh] ">
          {" "}
          Got a project in mind ?
        </h3>
        <Link
          to="/contact"
          className="flex justify-center items-center text-white font-bold mx-auto px-6 text-3xl md:text-5xl lg:mb-12 pb-4 underline-offset-8 underline"
        >
          <div className="flex items-center">
            Contact me! <FaChevronRight className="ml-2" />
          </div>
        </Link>

        <div class="mx-auto max-w-screen-3xl px-4 py-2 sm:px-6 lg:px-8 mt-[5vh]">
          <div class="mt-4 border-t border-white/10 pt-2">
            <div class="grid grid-cols-1 gap-2 lg:grid-cols-2">
              <p class="text-center text-xs text-white lg:text-left">
                Copyright &copy; 2022. Berk Çınar. All rights reserved.
              </p>

              <nav class="flex justify-center gap-x-4 text-xs text-white lg:justify-end">
                <a> Terms & Conditions </a>
                <a> Privacy Policy </a>
                <a> Cookies </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bottom;
